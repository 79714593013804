module.exports={
    author:"Only Kannada",
    title:'Only Kannada',
    icon:require("@/assets/icons/logo/OnlyKannada/favicon.png"),
    assetpath:'only_kannada',
    pwaData:{
        title:"Only Kannada",
        maskColor:"#30d158",
        msColor:"#da532c",
        themeColor:"#ffffff"
      },
    config_path:["https://vlite-config.firebaseio.com/prod/web/onlykannada/v1.json"],
    _fbId: "750716578846614",
    _googleId: "304594901466-q0vdreg0o3qpq5tr6v97ng0tbeh2rg48.apps.googleusercontent.com",
    playerConfig:{
        color: "#FF171D",
        logo:require("@/assets/icons/logo/OnlyKannada/watermark.svg"),
        fingerprint:true
    },
    pushConfiguration:[
        ['messagingId','458080364267'],
        ['vendorName','onlyKannada']
    ],
    colorCodes:{
        "--primary-clr": "#FF171D",
        "--btn-color": "linear-gradient( to right, rgb(255, 93, 93) 3%, #FF171D )",
        "--tag-color": "#DFBD03"
     }, 
    vendorAssets:'OnlyKannada',
    bodyID:"only_kannada",
    firebase:{
        primary:{
            apiKey: "AIzaSyCp08Z4lZhpsCCCNHvZaorWFPst1WlMeVc",
            authDomain: "onlykannada-542fa.firebaseapp.com",
            databaseURL: "https://onlykannada-542fa.firebaseio.com",
            projectId: "onlykannada-542fa",
            storageBucket: "onlykannada-542fa.appspot.com",
            messagingSenderId: "304594901466",
            appId: "1:304594901466:web:fe63a44fc3a19f23e908e3",
            measurementId: "G-W6YPSF495H"
        },
        secondary:{
            apiKey: "AIzaSyCp08Z4lZhpsCCCNHvZaorWFPst1WlMeVc",
            authDomain: "onlykannada-542fa.firebaseapp.com",
            databaseURL: "https://onlykannada-542fa.firebaseio.com",
            projectId: "onlykannada-542fa",
            storageBucket: "onlykannada-542fa.appspot.com",
            messagingSenderId: "304594901466",
            appId: "1:304594901466:web:fe63a44fc3a19f23e908e3",
            measurementId: "G-W6YPSF495H"
        },
        serverKey: 'AAAARutFWdo:APA91bFuKafkL20DvtgHsAgzNEdONvyDsrPzn8XjUHJ_QQw-G2p_w6--CYigBBkPMeISZvTXOBISkH2kCebNM7LHtAXssPzEXfaf37FT0Jfe3-8ENfCNKgh2QZYbnruTaXMJUwu--vE3',
        certificateKey: 'BFl6qqVspB8rhRq9yXQgeHIc5J_m5dlL8fN1MMKR-Id2P1Aiqf6-x9oVT6O6M8AerE2PQ8jkOl4QjcfJLz3Je_8'
    }
    }